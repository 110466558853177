import React from "react";
// import { graphql } from "gatsby";
import Root from "../Root";
import SEO from "../seo";

import BreadcrumsControls from "../Constant/BreadcrumsControls";
import PoolProfile from "./PoolProfile";
import PoolSecondProfile from "./PoolSecondProfile";
import PoolCoins from "./PoolCoins";
import NavajoAboveTable from "../Navajo/NavajoAboveTable";
import NavajoZillaBottom from "../Navajo/NavajoZillaBottom";

// export const query = graphql`
//   query($slug: String!) {
//     poolsJson(slug: { eq: $slug }) {
//       name
//       isVerified
//       numberOfSubPools
//       slug
//       description
//       website
//       links {
//         name
//         platform
//         url
//       }
//     }
//     allSubpoolsJson(filter: { isAlive: { eq: true }, poolProfile: { slug: { eq: $slug } } }) {
//       nodes {
//         coin {
//           abbreviation
//           name
//           slug
//         }
//         isAlive
//         isMerged
//         locations
//         mergedCoins {
//           abbreviation
//           name
//           slug
//         }
//         paymentInformation {
//           fee {
//             feeType
//             max
//             min
//           }
//           infoUrl
//           paymentInterval
//           paymentScheme
//           paymentThreshold {
//             max
//             min
//           }
//         }
//         slug
//       }
//     }
//   }
// `;

const Pool = ({ pageContext }) => {
  // const pool = data.poolsJson;
  // const subPools = data.allSubpoolsJson.nodes;

  const { pool } = pageContext;

  const breadcrums = [
    { to: "/", page: "Home" },
    { to: "/pools", page: "Pools" },
  ];

  return (
    <Root>
      <SEO
        title={`${pool.name} Mining Pools`}
        description={`View the best mining pools from ${pool.name} and see the pool fee, payment scheme, server location or minimum payout MintPond.com mining pool has to offer.`}
      />
      <BreadcrumsControls breadcrums={breadcrums} currentPage={`${pool.name}`} />
      <PoolProfile pool={pool} />
      <NavajoAboveTable />
      <PoolCoins subPools={pool.subPools} />
      <PoolSecondProfile pool={pool} subPools={pool.subPools} />
      <NavajoZillaBottom />
    </Root>
  );
};

export default Pool;
