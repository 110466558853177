import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Tooltip from "@material-ui/core/Tooltip";
import PublicIcon from "@material-ui/icons/Public";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import HelpIcon from "@material-ui/icons/Help";
import GroupWorkIcon from "@material-ui/icons/GroupWork";

import GradientLine from "../Constant/GradientLine";
import PoolCoinsTableHead from "./PoolCoinsTableHead";

import BlurUpImage from "../Constant/BlurUpImage";

import { mediaUrl } from "../../constant/constant";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
  },
  breadcrums: {
    marginTop: 10,
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      height: "90px",
      display: "flex",
      alignItems: "center",
    },
  },
  breadcrumsText: {
    display: "flex",
    flex: "1",
    [theme.breakpoints.down("md")]: {
      display: "block",
      flex: "1",
      marginBottom: "20px",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flex: "1",
    },
  },
  breadcrumsNavajo: {
    background: theme.palette.background.default,
    [theme.breakpoints.down("md")]: {
      width: "320px",
      height: "100px",
      margin: "0 auto",
    },
    [theme.breakpoints.up("md")]: {
      width: "728px",
      height: "90px",
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  headerImage: { height: 32, marginRight: 8 },
  headerTitle: {
    color: theme.palette.text.primary,
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "1.2",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "34px",
      lineHeight: "1.3",
    },
  },
  description: {
    fontWeight: "500",
    lineHeight: "1.6",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
  table: {
    minWidth: 1100,
  },
  tableRow: {
    display: "flex",
    borderBottom: "unset",
  },
  tableCell: {
    padding: "12px 0px 12px",
    display: "flex",
    alignItems: "center",
    borderBottom: "unset",
    height: "44px",
  },
  firstTableCell: {
    width: "23%",
  },
  normalTableCell: {
    width: "12%",
  },
  lastTableCell: {
    width: "5%",
  },
  noLink: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    transition: "color .1s ease-out",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  linkTitle: {
    fontSize: "1rem",
    fontWeight: "600",
    textTransform: "none",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const PoolCoins = props => {
  const { subPools } = props;
  const classes = useStyles(props);
  const [currentSubPools, setCurrentSubPools] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("fee");

  useEffect(() => {
    const sortedSubPools = stableSort(subPools, "asc", "status");
    setCurrentSubPools(sortedSubPools);
  }, [subPools]);

  const stableSort = (subPools, order, orderBy) => {
    if (orderBy === "name" && order === "asc") {
      return subPools.sort((a, b) => {
        if (b.coin.name > a.coin.name) {
          return -1;
        }
        if (b.coin.name < a.coin.name) {
          return 1;
        }
        return 0;
      });
    } else if (orderBy === "name" && order === "desc") {
      return subPools.sort((a, b) => {
        if (b.coin.name < a.coin.name) {
          return -1;
        }
        if (b.coin.name > a.coin.name) {
          return 1;
        }
        return 0;
      });
    } else if (orderBy === "locations" && order === "asc") {
      return subPools.sort((a, b) => {
        if (b.locations > a.locations) {
          return -1;
        }
        if (b.locations < a.locations) {
          return 1;
        }
        return 0;
      });
    } else if (orderBy === "locations" && order === "desc") {
      return subPools.sort((a, b) => {
        if (b.locations < a.locations) {
          return -1;
        }
        if (b.locations > a.locations) {
          return 1;
        }
        return 0;
      });
    } else if (orderBy === "scheme" && order === "asc") {
      return subPools.sort((a, b) => {
        if (b.paymentInformation.paymentScheme > a.paymentInformation.paymentScheme) {
          return -1;
        }
        if (b.paymentInformation.paymentScheme < a.paymentInformation.paymentScheme) {
          return 1;
        }
        return 0;
      });
    } else if (orderBy === "scheme" && order === "desc") {
      return subPools.sort((a, b) => {
        if (b.paymentInformation.paymentScheme < a.paymentInformation.paymentScheme) {
          return -1;
        }
        if (b.paymentInformation.paymentScheme > a.paymentInformation.paymentScheme) {
          return 1;
        }
        return 0;
      });
    } else if (orderBy === "minimum-pay" && order === "asc") {
      return subPools.sort((a, b) => {
        if (b.paymentInformation.paymentThreshold.min > a.paymentInformation.paymentThreshold.min) {
          return -1;
        }
        if (b.paymentInformation.paymentThreshold.min < a.paymentInformation.paymentThreshold.min) {
          return 1;
        }
        return 0;
      });
    } else if (orderBy === "minimum-pay" && order === "desc") {
      return subPools.sort((a, b) => {
        if (b.paymentInformation.paymentThreshold.min < a.paymentInformation.paymentThreshold.min) {
          return -1;
        }
        if (b.paymentInformation.paymentThreshold.min > a.paymentInformation.paymentThreshold.min) {
          return 1;
        }
        return 0;
      });
    } else if (orderBy === "maximum-pay" && order === "asc") {
      return subPools.sort((a, b) => {
        if (b.paymentInformation.paymentThreshold.max > a.paymentInformation.paymentThreshold.max) {
          return -1;
        }
        if (b.paymentInformation.paymentThreshold.max < a.paymentInformation.paymentThreshold.max) {
          return 1;
        }
        return 0;
      });
    } else if (orderBy === "maximum-pay" && order === "desc") {
      return subPools.sort((a, b) => {
        if (b.paymentInformation.paymentThreshold.max < a.paymentInformation.paymentThreshold.max) {
          return -1;
        }
        if (b.paymentInformation.paymentThreshold.max > a.paymentInformation.paymentThreshold.max) {
          return 1;
        }
        return 0;
      });
    } else if (orderBy === "pay-interval" && order === "asc") {
      return subPools.sort((a, b) => {
        if (b.paymentInformation.paymentInterval > a.paymentInformation.paymentInterval) {
          return -1;
        }
        if (b.paymentInformation.paymentInterval < a.paymentInformation.paymentInterval) {
          return 1;
        }
        return 0;
      });
    } else if (orderBy === "pay-interval" && order === "desc") {
      return subPools.sort((a, b) => {
        if (b.paymentInformation.paymentInterval < a.paymentInformation.paymentInterval) {
          return -1;
        }
        if (b.paymentInformation.paymentInterval > a.paymentInformation.paymentInterval) {
          return 1;
        }
        return 0;
      });
    } else if (orderBy === "fee" && order === "asc") {
      return subPools.sort((a, b) => {
        if (b.paymentInformation.fee.min > a.paymentInformation.fee.min) {
          return -1;
        }
        if (b.paymentInformation.fee.min < a.paymentInformation.fee.min) {
          return 1;
        }
        return 0;
      });
    } else if (orderBy === "fee" && order === "desc") {
      return subPools.sort((a, b) => {
        if (b.paymentInformation.fee.min < a.paymentInformation.fee.min) {
          return -1;
        }
        if (b.paymentInformation.fee.min > a.paymentInformation.fee.min) {
          return 1;
        }
        return 0;
      });
    } else if (orderBy === "status" && order === "asc") {
      return subPools.sort((a, b) => {
        return a.isAlive === b.isAlive ? 0 : a.isAlive ? -1 : 1;
      });
    } else if (orderBy === "status" && order === "desc") {
      return subPools.sort((a, b) => {
        return a.isAlive === b.isAlive ? 0 : a.isAlive ? 1 : -1;
      });
    } else {
      return subPools;
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    const newSubPools = stableSort(currentSubPools, isAsc ? "desc" : "asc", property);
    setCurrentSubPools(newSubPools);
  };

  const getName = subPool => {
    const image = {
      id: `${subPool.coin.slug}-preview`,
      alt: subPool.coin.name,
      title: subPool.coin.name,
      src: `${mediaUrl}/assets/img/coins/${subPool.coin.abbreviation.toLowerCase()}.png`,
      blurSrc: `${mediaUrl}/assets/img/coins/${subPool.coin.abbreviation.toLowerCase()}.png`,
      size: { width: 24, height: 24 },
    };

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: 8 }}>
          <Link className={classes.link} to={`/coins/${subPool.coin.slug}`}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ width: 24, height: 24, marginRight: 8 }}>
                <BlurUpImage image={image} />
              </span>
              <span>
                <Typography className={classes.linkTitle} variant="subtitle1">
                  {subPool.coin.name} ({subPool.coin.abbreviation})
                </Typography>
              </span>
            </div>
          </Link>
        </span>
        {subPool.mergedCoins.length !== 0 ? (
          <span>
            <div style={{ display: "flex" }}>
              <a className={classes.link} href={subPool.url} target="_blank" rel="noopener noreferrer">
                <Tooltip title="Merged widh: " placement="top" arrow>
                  <GroupWorkIcon fontSize="small" />
                </Tooltip>
              </a>
            </div>
          </span>
        ) : null}
      </div>
    );
  };

  const getLocations = locations => {
    if (locations && locations.length) {
      if (locations.length === 0) {
        return (
          <div className={classes.noLink}>
            <Typography className={classes.linkTitle} variant="subtitle1">
              -
            </Typography>
          </div>
        );
      } else if (locations.length === 1) {
        return (
          <div className={classes.noLink}>
            <Typography className={classes.linkTitle} variant="subtitle1">
              {locations.join(", ")}
            </Typography>
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex" }}>
            <Tooltip title={locations.join(", ")} placement="top" arrow>
              <PublicIcon fontSize="small" />
            </Tooltip>
          </div>
        );
      }
    } else {
      return (
        <div className={classes.noLink}>
          <Typography className={classes.linkTitle} variant="subtitle1">
            -
          </Typography>
        </div>
      );
    }
  };

  const getPayInterval = interval => {
    if (typeof interval !== "undefined" && interval !== null) {
      if (interval === 0) {
        return "Instant";
      } else {
        const minutes = Math.floor(interval / 60);

        if (minutes > 59) {
          const hours = Math.floor(minutes / 60);

          if (hours > 23) {
            const days = Math.floor(hours / 24);
            return `${days}d`;
          } else {
            return `${hours}h`;
          }
        } else {
          return `${minutes}m`;
        }
      }
    } else {
      return "-";
    }
  };

  const getFee = fee => {
    const minFee = fee.min === 314 ? "-" : `${fee.min}%`;
    const maxFee = fee.max === 314 ? "-" : `${fee.max}%`;

    if (fee) {
      if (fee.feeType === "Variable") {
        return `${minFee}-${maxFee}`;
      } else {
        return `${minFee}`;
      }
    } else {
      return "-";
    }
  };

  const getIsAlive = isAlive => {
    if (typeof isAlive !== "undefined" && isAlive !== null) {
      if (isAlive) {
        return (
          <Tooltip title="Pool is online" placement="top" arrow>
            <ThumbUpIcon fontSize="small" style={{ color: "green" }} />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title="Pool is offline" placement="top" arrow>
            <ThumbDownIcon fontSize="small" style={{ color: "red" }} />
          </Tooltip>
        );
      }
    } else {
      return (
        <Tooltip title="We don't know the status" placement="top" arrow>
          <HelpIcon fontSize="small" />
        </Tooltip>
      );
    }
  };

  return (
    <div>
      <GradientLine />
      <Container maxWidth="lg">
        <TableContainer>
          <Table className={classes.table}>
            {/* New Head */}
            <PoolCoinsTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />

            <TableBody>
              {currentSubPools.map((subPool, index) => {
                return (
                  <TableRow className={classes.tableRow} key={index} tabIndex={-1}>
                    <TableCell className={clsx(classes.tableCell, classes.firstTableCell)} component="th" scope="row" align="left">
                      {getName(subPool)}
                    </TableCell>
                    <TableCell className={clsx(classes.tableCell, classes.normalTableCell)} component="th" scope="row" align="left">
                      {getLocations(subPool.locations)}
                    </TableCell>
                    <TableCell className={clsx(classes.tableCell, classes.normalTableCell)} component="th" scope="row" align="left">
                      <div className={classes.noLink}>
                        <Typography className={classes.linkTitle} variant="subtitle1">
                          {typeof subPool.paymentInformation.paymentScheme === "undefined" ? "-" : subPool.paymentInformation.paymentScheme}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell className={clsx(classes.tableCell, classes.normalTableCell)} component="th" scope="row" align="left">
                      <div className={classes.noLink}>
                        <Typography className={classes.linkTitle} variant="subtitle1">
                          {subPool.paymentInformation.paymentThreshold.min === 314
                            ? "-"
                            : `${subPool.paymentInformation.paymentThreshold.min} ${subPool.coin.abbreviation}`}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell className={clsx(classes.tableCell, classes.normalTableCell)} component="th" scope="row" align="left">
                      <div className={classes.noLink}>
                        <Typography className={classes.linkTitle} variant="subtitle1">
                          {subPool.paymentInformation.paymentThreshold.max === 314
                            ? "-"
                            : `${subPool.paymentInformation.paymentThreshold.max} ${subPool.coin.abbreviation}`}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell className={clsx(classes.tableCell, classes.normalTableCell)} component="th" scope="row" align="left">
                      <div className={classes.noLink}>
                        <Typography className={classes.linkTitle} variant="subtitle1">
                          {getPayInterval(subPool.paymentInformation.paymentInterval)}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell className={clsx(classes.tableCell, classes.normalTableCell)} component="th" scope="row" align="left">
                      <div className={classes.noLink}>
                        <Typography className={classes.linkTitle} variant="subtitle1">
                          {getFee(subPool.paymentInformation.fee)}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell className={clsx(classes.tableCell, classes.lastTableCell)} component="th" scope="row" align="right">
                      <div style={{ display: "flex" }}>{getIsAlive(subPool.isAlive)}</div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      <GradientLine />
    </div>
  );
};

export default PoolCoins;
