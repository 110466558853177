import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReadMoreReact from "read-more-react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import WebIcon from "@material-ui/icons/Web";
import ExploreIcon from "@material-ui/icons/Explore";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import RedditIcon from "@material-ui/icons/Reddit";
import ForumIcon from "@material-ui/icons/Forum";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkIcon from "@material-ui/icons/Link";

import OutboundLink from "../Constant/OutboundLink";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    [theme.breakpoints.down("md")]: {
      padding: "40px 0px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "80px",
    },
  },
  gridRoot: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "unset",
    },
  },
  gridItem: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "50%",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  headerTitle: {
    color: theme.palette.text.primary,
    fontWeight: "700",
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "1.2",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "34px",
      lineHeight: "1.3",
    },
  },
  description: {
    fontWeight: "500",
    lineHeight: "1.6",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
  readMore: {
    fontWeight: "500",
    lineHeight: "1.6",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
  urlLink: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const PoolSecondProfile = props => {
  const { pool, subPools } = props;
  const classes = useStyles(props);

  const getButtonLink = (link, index, icon) => {
    return (
      <OutboundLink
        key={index}
        href={link.url}
        category="Pool Profile"
        action="Link Click"
        label={`${pool.name}: ${link.name} - ${link.url}`}
        value={20}
        style={{ textDecoration: "none", marginRight: 10 }}
      >
        <Button
          style={{ textTransform: "none", fontWeight: "700", marginBottom: 10 }}
          variant="contained"
          color="primary"
          size="large"
          startIcon={icon}
          disableElevation
        >
          {link.name}
        </Button>
      </OutboundLink>
    );
  };

  const getLinks = () => {
    if (pool.links.length === 0) {
      return null;
    } else {
      return pool.links.map((link, index) => {
        switch (link.platform) {
          case "website":
            return getButtonLink(link, index, <WebIcon />);
          case "explorer":
            return getButtonLink(link, index, <ExploreIcon />);
          case "twitter":
            return getButtonLink(link, index, <TwitterIcon />);
          case "facebook":
            return getButtonLink(link, index, <FacebookIcon />);
          case "discord":
            return getButtonLink(link, index, <LinkIcon />);
          case "telegram":
            return getButtonLink(link, index, <TelegramIcon />);
          case "reddit":
            return getButtonLink(link, index, <RedditIcon />);
          case "bitcointalk":
            return getButtonLink(link, index, <ForumIcon />);
          case "github":
            return getButtonLink(link, index, <GitHubIcon />);
          default:
            return getButtonLink(link, index, <LinkIcon />);
        }
      });
    }
  };

  const getSmallestFee = () => {
    const sorted = subPools
      .filter(a => a.paymentInformation.fee.min !== 314)
      .map(a => {
        return a.paymentInformation.fee.min;
      })
      .sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      });

    if (sorted.length > 0) {
      return `${sorted[0]}%`;
    } else {
      return "-";
    }
  };

  const getBiggestFee = () => {
    const sorted = subPools
      .filter(a => a.paymentInformation.fee.min !== 314)
      .map(a => {
        return a.paymentInformation.fee.min;
      })
      .sort((a, b) => {
        if (a > b) {
          return -1;
        }
        if (a < b) {
          return 1;
        }
        return 0;
      });

    if (sorted.length > 0) {
      return `${sorted[0]}%`;
    } else {
      return "-";
    }
  };

  const getPopularCoins = () => {
    let abbreviations = [];
    let finalCoins = [];

    for (let i = 0; i < subPools.length; i++) {
      let subPool = subPools[i];

      if (!abbreviations.includes(subPool.coin.abbreviation)) {
        finalCoins.push(subPool.coin);
      }
    }

    if (finalCoins === 0) {
      return `No coin can be currently mined on ${pool.name}.\n\nPlease contact us if this this information is inaccurate.\n\n*Autogenerated description.`;
    } else if (finalCoins === 1) {
      const oneCoin = finalCoins[0];
      return `Currently only ${oneCoin.name} (${oneCoin.abbreviation}) can be mined on ${pool.name}.\n\nPlease contact us if this this information is inaccurate.\n\n*Autogenerated description.`;
    } else if (finalCoins > 2) {
      const coins = finalCoins
        .slice(0, 2)
        .map(coin => `${coin.name} (${coin.abbreviation})`)
        .join(", ");
      return `Some of the coins that can be mined on ${pool.name} include ${coins}.\n\nPlease contact us if this this information is inaccurate.\n\n*Autogenerated description.`;
    } else {
      const coins = finalCoins
        .slice(0, 3)
        .map(coin => `${coin.name} (${coin.abbreviation})`)
        .join(", ");
      return `Some of the coins that can be mined on ${pool.name} include ${coins}.\n\nPlease contact us if this this information is inaccurate.\n\n*Autogenerated description.`;
    }
  };

  const getDescription = () => {
    if (pool.description === "") {
      if (pool.numberOfSubPools === 0) {
        return `${pool.name} is a cryptocurrency mining pool. ${getPopularCoins()}`;
      } else if (pool.numberOfSubPools === 1) {
        return `${pool.name} is a cryptocurrency mining pool that operates with a fee of ${getSmallestFee()}. ${getPopularCoins()}`;
      } else {
        return `${
          pool.name
        } is a cryptocurrency mining pool that operates with fees ranging from ${getSmallestFee()} and ${getBiggestFee()}. ${getPopularCoins()}`;
      }
    } else {
      return `${pool.description}`;
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid className={classes.gridRoot} container spacing={3}>
          <Grid className={classes.gridItem} item xs={6}>
            <Typography className={classes.headerTitle} variant="h2">
              About {pool.name}
            </Typography>
            <Typography className={classes.readMore} variant="subtitle1">
              <ReadMoreReact text={getDescription()} min={180} ideal={220} max={1000} readMoreText="read more" />
            </Typography>
          </Grid>
          <Grid className={classes.gridItem} item xs={6}>
            <Typography className={classes.headerTitle} variant="h2">
              Links
            </Typography>
            <div>{getLinks()}</div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default PoolSecondProfile;
