import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

import OutboundLink from "../Constant/OutboundLink";
import NavajoZillaDesktop from "../Navajo/NavajoZillaDesktop";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    [theme.breakpoints.down("md")]: {
      margin: "40px 0px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "60px 0px",
    },
  },
  profile: {
    margin: theme.spacing(2.5, 0),
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      height: "90px",
      display: "flex",
      alignItems: "center",
    },
  },
  navajo: {
    background: theme.palette.background.default,
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
      display: "flex",
      width: "320px",
      height: "100px",
      justifyContent: "flex-end",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      width: "728px",
      height: "90px",
      justifyContent: "flex-end",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  headerTitle: {
    color: theme.palette.text.primary,
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
      lineHeight: "1.2",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "34px",
      lineHeight: "1.3",
    },
  },
}));

const PoolProfile = props => {
  const { pool } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.profile}>
          <div className={classes.header}>
            <span>
              <Typography className={classes.headerTitle} variant="h1" style={{ marginBottom: 4 }}>
                {pool.name}
              </Typography>

              <OutboundLink
                href={pool.website}
                category="Pool Profile"
                action="Visit Pool Website"
                label={pool.name}
                value={100}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disableElevation
                  style={{ textTransform: "none", fontWeight: "700" }}
                >
                  Visit Website
                </Button>
              </OutboundLink>
            </span>
          </div>
          <div className={classes.navajo}>
            <NavajoZillaDesktop />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PoolProfile;
